<template>
  <div class="ground-detail">
    <div
      class="data-page__title _flex _flex-justify__between _flex-align__center _font-size__heading3"
    >
      <div class="_flex data-page__title__left">
        <!-- <div>
          <span>Location：</span>
          <span class="_font-weight__600">{{ resolution(detail, 'location') }}</span>
        </div> -->
        <div>
          <span>Created Date：</span>
          <span class="_font-weight__600">{{ resolution(detail, 'createdTime') }}</span>
        </div>
      </div>
      <el-button type="info" @click="$emit('change')">Back</el-button>
    </div>

    <div class="ground-detail__content">
      <img :src="detail.filePath" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss" scoped>
.ground-detail {
  width: 100%;

  .data-page__title__left {
    > div {
      margin-right: 45px;
    }
  }

  &__content {
    padding: 30px;
    background: var(--color-white);
    position: relative;

    &::before {
      content: 'Tilt Angle Condition';
      position: absolute;
      left: 30px;
      top: 30px;
      z-index: 2;
      font-weight: 600;
      font-size: 18px;
    }
    img {
      max-width: 1100px;
      max-height: 770px;
      margin: 0 auto;
      display: block;
      object-fit: cover;
    }
  }
}
</style>
